.rs-input-group {
	border: none;
	border-radius: 100px;
}

.rs-input-group:not(.rs-input-group-disabled).rs-input-group-focus,
.rs-input-group:focus-within {
	outline: none;
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
	background: #60d081;
}

.rs-input-group:not(.rs-input-group-disabled):hover,
.rs-input-group:not(.rs-input-group-disabled).rs-input-group-focus,
.rs-input-group:focus-within,
.rs-input:focus,
.rs-input:hover:not(:disabled) {
	background: #f3f4f6;
}

.rs-btn-link {
	color: #60d081;
	text-decoration: none;
}

.rs-btn-link:hover {
	color: #60d081;
	text-decoration: none;
}

.rs-btn-primary {
	background: #60d081;
	width: 92px;
	height: 40px;
	padding: 8px 24px 8px 24px;
	border-radius: 100px;
}

.rs-btn-primary:hover {
	background: #c4fac4;
}

.rs-calendar-table-cell-content:hover {
	background: #c4fac4;
	color: #6b7280;
}

.rs-picker-daterange .rs-input-group-addon,
.rs-input-group.rs-input-group-inside,
.rs-picker-daterange .rs-input-group-addon:hover {
	background: #ffffff;
}

.rs-btn-close .rs-icon {
	color: #6b7280;
}

.rs-btn-close .rs-icon:hover,
.rs-btn-close:focus,
.rs-btn-close:hover {
	color: #60d081;
}

.rs-picker-popup
	.rs-calendar
	.rs-calendar-table-cell-selected:hover
	.rs-calendar-table-cell-content {
	background-color: #c4fac4 !important;
}

.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
	box-shadow: 0px 0px 2px 0px #60d081;
}

.rs-input {
	border-radius: 100px !important;
	padding: 8px 14px;
	cursor: pointer;
}

.rs-picker-popup.rs-picker-popup-daterange {
	left: 900px !important;
	top: 575px !important;
}

/* checkbox */

.rs-checkbox-checked .rs-checkbox-control .rs-checkbox-inner:before,
.rs-checkbox-indeterminate .rs-checkbox-control .rs-checkbox-inner:before {
	background: #60d081;
	border-color: #60d081;
}
